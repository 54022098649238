<template>
  <master-layout
    :on_active="on_active"
    @create-card="createCard"
    class="relative"
  >
    <main class="mx-auto w-87 pb-11">
      <section>
        <client-search></client-search>
        <client-form
          v-if="show_modal"
          @close-card-modal="createCard()"
        ></client-form>

        <section
          class="absolute bottom-6 left-6 xl:left-96"
          v-if="new_app_version"
          @click="updateVersion"
        >
          <div class="cursor-pointer">
            <div
              class="
                bg-primary
                animate-bounce
                shadow-md
                hover:shadow-xl
                px-4
                py-4
                text-white text-sm
                rounded-md
              "
            >
              New Version Available! Click to update
            </div>
          </div>
        </section>
      </section>
    </main>
  </master-layout>
</template>

<script>
import MasterLayout from '../../components/Layout/MasterLayout.vue'
import ClientForm from '../../components/Create/ClientForm.vue'
import ClientSearch from '../../components/Reports/report-client/ClientSearch.vue'

export default {
  components: {
    MasterLayout,
    ClientForm,
    ClientSearch
  },

  props: {
    on_active: {
      default: 'reports',
      type: String
    }
  },

  data() {
    return {
      show_modal: false,
      show_pet_details: false,
      report_page: false
    }
  },

  computed: {
    new_app_version() {
      return this.$store.state.new_app_version
    }
  },

  methods: {
    petProfile() {
      this.show_pet_details = !this.show_pet_details
    },
    showReportPage() {
      this.report_page = !this.report_page
    },
    createCard() {
      this.show_modal = !this.show_modal
    },

    updateVersion() {
      this.$store.dispatch('addAppVersionToLocalStorage')
    }
  },

  beforeMount() {
    this.$store.dispatch('middleware/isLogged')
  },

  mounted() {
    this.$store.dispatch('appVersion')
  }
}
</script>

<style></style>
