<template>
  <div>
    <svg
      class="fill-current inline-block"
      :class="[width, height, color]"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.7"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.9271 9.61245C6.84195 11.438 3.66895 11.3567 1.68074 9.36845C-0.392277 7.29544 -0.392277 3.93442 1.68074 1.8614C3.75375 -0.211613 7.11478 -0.211613 9.18779 1.8614C11.1144 3.78797 11.2505 6.82698 9.5961 8.91073L12.1647 11.4793C12.354 11.6686 12.354 11.9754 12.1647 12.1647C11.9755 12.354 11.6686 12.354 11.4793 12.1647L8.9271 9.61245ZM8.58283 8.7635C6.84392 10.5024 4.0246 10.5024 2.2857 8.7635C0.54679 7.0246 0.54679 4.20528 2.2857 2.46637C4.0246 0.727469 6.84392 0.72747 8.58283 2.46637C10.3217 4.20528 10.3217 7.0246 8.58283 8.7635Z"
        fill="black"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: 'w-4'
    },
    height: {
      type: String,
      default: 'h-4'
    },
    color: {
      type: String
    }
  }
}
</script>

<style></style>
