<template>
  <header>
    <section>
      <section class="my-24">
        <section class="mt-12">
          <section class="mb-14">
            <section
              class="
                flex
                sm:w-98
                w-full
                bg-white
                shadow-sm
                rounded
                px-4
                py-1
                items-center
                align-middle
              "
            >
              <input
                type="search"
                name=""
                id=""
                class="w-full py-1 outline-none text-lg"
                placeholder="Search Clients"
                v-model="client_search"
              />
              <search-icon
                class="ml-3"
                :width="'w-5'"
                :height="'h-5'"
              ></search-icon>
            </section>
          </section>
          <section
            class="
              grid grid-cols-1
              sm:grid-cols-2
              xl:grid-cols-4
              gap-x-8 gap-y-6
            "
          >
            <div
              class="
                shadow
                bg-white
                flex flex-col
                place-items-center
                rounded
                px-2
                py-6
                relative
                card
              "
              v-for="(client, index) in new_clients"
              :key="index"
            >
              <div class="mt-6">
                <user-icon :width="'w-20'" :height="'h-20'"></user-icon>
              </div>
              <div class="text-center space-y-4 w-full">
                <h1 class="text-lg font-medium leading-10 capitalize">
                  {{ client.client_details ? client.client_details.name : '' }}
                </h1>
                <small class="text-base text-gray-500 text-opacity-80">{{
                  client.client_details ? client.client_details.bus_name : ''
                }}</small>
              </div>
              <div class="absolute top-0 h-1/5 w-full card-top-bar">
                <div class="flex justify-between px-4 py-3">
                  <a
                    href="javascript:;"
                    class="hover:text-primary cursor-pointer text-gray-400"
                    @click="viewProfile(client.id)"
                  >
                    view
                  </a>
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
      <loading-model v-if="loading"></loading-model>
    </section>
  </header>
</template>

<script>
import { mapState } from 'vuex'
import SearchIcon from '../../Icons/searchIcon.vue'
import UserIcon from '../../Icons/userIcon.vue'
import LoadingModel from '../../Utilities/loadingModel.vue'
export default {
  components: { SearchIcon, UserIcon, LoadingModel },
  data() {
    return {
      client_id: '',
      new_clients: [],
      client_search: ''
    }
  },
  created() {
    this.$store.dispatch('clients/getClient')
  },
  computed: mapState('clients', ['clients', 'loading']),
  watch: {
    client_search(val) {
      this.new_clients = []
      this.searchClient(val)
    },

    clients() {
      this.new_clients = this.clients
    }
  },
  methods: {
    searchClient(val) {
      this.clients.forEach(info => {
        var client_name = info.client_details.name.toLowerCase()
        var lowercase_val = val.toLowerCase()
        if (client_name.includes(lowercase_val)) {
          this.new_clients.push(info)
        }
      })
    },
    viewProfile(val) {
      this.client_id = val
      this.$router.push({
        name: 'Reports_Client_Profile',
        params: { client_id: this.client_id }
      })
    }
  },
  mounted() {
    if (this.clients != undefined) {
      this.new_clients = this.clients
    }
  }
}
</script>

<style scoped></style>
